$mobile-threshold: 1025px;
$laptop-threshold: 1560px;

/* MAIN STYLE COLORS */
$color-style-white: #ffffff;
$color-style-grey: #465160;

$color-style-blue: #6046ff;
$color-style-blue-light: #46a6ff;
$color-style-lightblue: #46a6ff;
$color-style-light-blue: #46a6ff;
$color-style-light-blue: #46a6ff;
$color-hover-blue-light: #74bcff;
$color-hover-lightblue: #74bcff;
$color-hover-light-blue: #74bcff;
$color-hover-light-blue: #74bcff;

$color-style-purple: #9488f0;
$color-hover-purple: #afa5fb;
$color-hover-purplelight: #afa5fb;
$color-hover-purple-light: #afa5fb;
$color-hover-lightpurple: #afa5fb;
$color-hover-light-purple: #afa5fb;

$color-style-green: #12b886;
$color-style-green-light: #22d49f;
$color-style-lightgreen: #22d49f;
$color-style-light-green: #22d49f;
$color-hover-green-light: #3aeab5;
$color-hover-lightgreen: #3aeab5;
$color-hover-light-green: #3aeab5;
$color-style-green-light-opacity-20: rgba(34, 212, 159, 0.2);

$color-style-red: #ff646d;
$color-hover-red: #ff8c93;
$color-style-red-opacity-20: rgba(255, 100, 109, 0.2);

/* BACKGROUND COLORS */
$color-bg-100: #181d23;
$color-bg-90: #1e242c;
$color-bg-80: #232a32;
$color-bg-70: #29313a;
$color-bg-60: #2d3642;
$color-bg-50: #343d48;

/* ELEMENT COLORS */
$color-element-text: #545c66;
$color-element-text-light: #6d7986;
$color-element-textlight: #6d7986;
$color-element-light-text: #6d7986;
$color-element-lighttext: #6d7986;

$color-element-btn-hover: #374250;
$z-index-wallet-data: 1;

$color-element-input: #1f262f;
