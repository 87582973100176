@import './_variables';

.provider-menu {
  position: relative;
  transition: border 0.25s ease;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  border-radius: 16px;
  width: 312px;
  height: 480px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px transparent solid;
    transition: all 0.25s ease;
    pointer-events: none;
    border-radius: 16px;
    z-index: 2;
  }

  &--welcome {
    width: 720px;
  }

  @media (max-width: ($mobile-threshold)) {
    flex-flow: column;
    border-radius: 0;
    width: 100%;
    height: auto;
    gap: 24px;
    overflow: visible;
    min-height: 412px;

    &:before {
      content: none;
    }
  }

  &--error {
    &::before {
      border-color: $color-style-red;
    }
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    z-index: 2;
    transition: all 0.25s ease;

    &:hover {
      background-color: $color-element-btn-hover;

      svg path {
        fill: #fff;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-step {
    padding: 16px 16px 24px;
    background: $color-bg-90;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 24px;
    align-items: stretch;

    @media (max-width: $mobile-threshold) {
      padding: 0;
      justify-content: space-between;
      background: none;
      min-height: 412px;
    }

    &__header {
      flex: 0 0 auto;
    }

    &__footer {
      flex: 0 0 auto;
    }

    &__body {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      justify-content: center;
      flex: 1 0 280px;
      max-width: 296px;
      margin: 0 auto;

      @media (max-width: $mobile-threshold) {
        flex: 0 0 auto;
        max-width: none;
        margin: 0;
      }
    }

    &__title {
      text-align: center;
      color: $color-style-white;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;

      @media (min-width: ($mobile-threshold+0.1)) {
        .part {
          display: block;
        }
      }
    }
  }

  &-links {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    font-size: 10px;
    line-height: 1;
    justify-content: center;
  }

  &__link {
    color: $color-style-purple;
    &:hover {
      color: $color-hover-purple;
    }
  }

  &__welcome {
    flex: 1 0 0;

    @media (max-width: $mobile-threshold) {
      flex: 0 0 auto;
      margin: 0;
    }
  }

  &__step {
    flex: 0 0 312px;
    display: flex;
    flex-flow: column;
    align-items: stretch;

    @media (max-width: $mobile-threshold) {
      flex: 1 0 0;
    }
  }

  &__welcome ~ &__step {
    flex: 0 0 320px;
  }

  @media (max-width: $mobile-threshold) {
    &__welcome ~ &__step {
      flex: 0 0 auto;
    }

    &__welcome ~ &__step &-step {
      min-height: 0;
    }
  }

  &__features {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Sora', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
  }

  &__feature {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &::before {
      content: '';
      flex: 0 0 auto;
      width: 17px;
      height: 16px;
      margin-right: 9px;
      background: center center no-repeat;
    }

    &.pro {
      color: $color-style-green-light;
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 16' width='17' height='16'%3E%3Cpath d='m1.5 8 5 5 9-9' stroke='%2322D49F' stroke-width='2'/%3E%3C/svg%3E");
      }
    }
    &.con {
      color: $color-style-red;
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 16' width='17' height='16'%3E%3Cpath d='m8.4 7.9-5-5m5 5 5-5m-5 5-5 5m5-5 5 5' stroke='%23FF646D' stroke-width='2'/%3E%3C/svg%3E");
      }
    }
  }

  &-welcome {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: $color-bg-100;
    padding: 24px;
    gap: 16px;
    position: relative;
    z-index: 21;

    @media (max-width: $mobile-threshold) {
      padding: 16px 24px 24px;
      margin: -16px -16px 0;
      background: $color-bg-100;
    }

    &__image {
      margin: 0 auto;
      flex: 0 0 auto;
      position: relative;
      z-index: 1;
      order: 0;

      svg {
        position: relative;
        z-index: 1;
        max-width: 100%;
        height: auto;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 400px;
        background: radial-gradient(
          50% 50% at center,
          rgba(123, 97, 255, 0.6) 0%,
          rgba(24, 29, 35, 0) 100%
        );
        pointer-events: none;
      }

      @media (max-width: $mobile-threshold) {
        display: none;
      }
    }

    &__title {
      position: relative;
      z-index: 2;
      order: 1;
      font-family: 'Sora', sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 1;
      background: linear-gradient(104.04deg, #8373ff 0%, #ff57cf 52.08%, #3aeab5 100%);
      background-clip: text;
      color: transparent;

      @media (max-width: $mobile-threshold) {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    &__title,
    &__body,
    &__footer {
      flex: 0 0 auto;
    }

    &__body {
      order: 3;
      max-width: 300px;
      margin: 0 auto;
    }

    &__footer {
      order: 4;
      margin-top: auto;
      font-size: 12px;
      line-height: 1.5;
      color: $color-element-text-light;
      max-width: 324px;
    }
  }

  &-progress {
    width: 246px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    gap: 24px;

    @media (max-width: $mobile-threshold) {
      width: auto;
      max-width: 400px;
    }

    &__request {
      text-align: center;
      color: $color-style-white;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      margin: 0;
      display: flex;
      flex-flow: column;
      gap: 16px;

      .comment {
        color: $color-element-text;
        font-size: inherit;
        line-height: inherit;
      }
    }

    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 42px;
        height: 20px;

        path {
          stroke-dasharray: 87 10;
          stroke-dashoffset: 0;
          stroke: $color-style-purple;
          animation: infinity-stroke 1.2s linear infinite;
        }
      }

      @keyframes infinity-stroke {
        from {
          stroke-dashoffset: 0;
        }
        to {
          stroke-dashoffset: 97;
        }
      }
    }

    &__icon {
      width: 64px;
      height: 64px;
      margin: 0 auto;

      .icon > * {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }
  }

  &-providers {
    display: flex;
    flex-flow: column;
    width: 248px;
    margin: 0;

    @media (max-width: $mobile-threshold) {
      width: auto;
    }

    &__entry {
      flex: 1 0 auto;
      margin: 0 0 16px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: $mobile-threshold) {
        flex: 0 0 auto;
      }
    }

    &__subtitle {
      flex: 0 0 auto;
      margin-top: 8px;
      font-size: 11px;
      line-height: 1.5;
      color: $color-element-text-light;
      display: none;

      @media (max-width: $mobile-threshold) {
        display: block;
      }
    }

    &__button {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 100%;
      height: 100%;
      padding: 16px 12px;
      border-radius: 8px;
      transition: all 0.25s ease;
      background-color: $color-bg-70;
      color: $color-element-text-light;
      transition: all 0.25s ease;
      cursor: pointer;
      margin: 0;

      font-family: 'Sora', sans-serif;
      font-size: 16px;
      line-height: 1.25;

      @media (max-width: $mobile-threshold) {
        flex-flow: row nowrap;
        padding: 12px 24px;

        height: 64px;
      }

      &:hover {
        border-color: $color-element-btn-hover;
        background-color: $color-element-btn-hover;
        color: $color-style-white;
      }

      &.connecting {
        border-color: $color-bg-70;
        background-color: $color-bg-90;
        pointer-events: none;
      }

      .icon {
        width: 56px;
        height: 56px;
        position: relative;

        @media (max-width: $mobile-threshold) {
          width: 40px;
          height: 40px;
        }

        &:before {
          position: absolute;
          top: -8px;
          left: 100%;
          font-size: 10px;
          line-height: 1.6;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &--install {
        .icon {
          filter: grayscale(1);
        }
      }
    }
  }

  &-explorers {
    margin: -16px -8px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &__link {
      flex: 0 0 auto;
      width: calc(50% - 16px);
      margin: 16px 8px 0;
      padding: 24px 8px;
      border-radius: 8px;
      background: $color-bg-70;
      color: $color-element-text-light;
      transition: all 0.25s ease;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;

      &:hover {
        color: $color-style-white;
        background-color: #455161;
        cursor: pointer;
      }

      .icon {
        width: 32px;
        height: 32px;
        margin-bottom: 16px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__disconnect {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-style-white;
    width: 150px;
    height: 48px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 8px;
    background-color: $color-style-red;

    transition: all 0.25s ease;

    &:hover {
      background-color: $color-hover-red;
    }
  }

  &__error {
    flex: 0 0 auto;
    margin: 24px 0 0;
    padding: 8px 16px;
    background: linear-gradient(104.04deg, #2d10ff 0%, #ff0000 100%);
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    border-radius: 8px;
    color: $color-style-white;
    flex: 0 0 auto;
    min-height: 36px;
  }

  &--welcome &__error {
    margin-top: 16px;
  }

  &__disclaimer {
    font-size: 12px;
    line-height: 1.5;
    color: $color-element-text-light;
    text-align: center;

    .part {
      display: block;
    }

    @media (max-width: $mobile-threshold) {
      font-size: 11px;
      line-height: 1.5;
      margin: 0 -16px;

      .part {
        display: inline;
      }
    }

    a {
      color: $color-style-purple;
      transition: color 0.25s ease;
      &:hover {
        color: $color-hover-purple;
      }
    }
  }
}
